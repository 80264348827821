import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  HederMenuVisible: true
};

export const landingSlice = createSlice({
  name: "landing",
  initialState,
  reducers: {
    setHeaderMenuVisible: (state, action) => {
       
        state.HederMenuVisible = action.payload
    },
  },
},
);

export const {setHeaderMenuVisible} = landingSlice.actions;

export default landingSlice.reducer;
