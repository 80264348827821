import './Header.css'
import menu from '../../images/header/menu__btn.webp'
import {useDispatch} from 'react-redux'
import {setHeaderMenuVisible} from '../../screens/Landing/LandingSlice'
import { useState} from "react";
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next";
import changeLocale from '../../images/header/changeLocale.svg'



function Header(props){
    const dispatch = useDispatch()
    const { t} = useTranslation();
    const [menuVisible, setMenuVisible] = useState(false)

    

    const changeMenuVisible = () =>{
        setMenuVisible(!menuVisible)
        dispatch(setHeaderMenuVisible(menuVisible))
    }
    const changeLocaleMenuVisible = () => {
        if(menuVisible){
            setMenuVisible(false)
            dispatch(setHeaderMenuVisible(menuVisible))
        }
        props.setLocalemodalVisible(!props.localemodalVisible)
       
    }
    const rotateVariants = {
        initial: {
          rotate: 0,
        },
        rotated: {
          rotate: -95,
        },
      };
    

    return(
        <div  className={props.header_class}>
            <div>
                <a name='main' href='#'><img alt='logo' src={props.logo} className='header__logo'/></a>
            </div>
            
            <div className='header__links'>
                <a name='whyYG' href='#whyYG' className='header__links--link'>{t("advantages")}</a>
                {/* <a  name='howGetVPN' href='#howGetVPN' className='header__links--link'>{t("howToGetVPN")}</a> */}
                <a  name='tarifs' href='#tarifs' className='header__links--link'>{t("tariffs")}</a>
                <a  name='affiliateProgram' href='#affiliateProgram' className='header__links--link'>{t("affiliateProgram")}</a>
                <a  name='anyQwestions' href='#anyQwestions' className='header__links--link'>{t("contacts")}</a>
            </div>
            <div className='header__buttons'>
                <a  name='tg' href='https://t.me/YourGuardVPN_bot'><button className='header__button'>{t("tryForFree")}</button></a>
                <div className='header__changeLocale--wrap'>
                    <img onClick={changeLocaleMenuVisible} className='header__changeLocale' src={changeLocale}/>
                </div>
                
            </div>
            <motion.img  
                alt='menu'
                initial="initial"
                animate={menuVisible ? "rotated" : "initial"}
                variants={rotateVariants}
                transition={{ duration: 0.5 }}
                src={menu} 
                onClick={changeMenuVisible} 
                className='header__menu' 
            />
        </div>
    )
}
export default Header