import './AffProgramCalculator.css'
import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {useState, useEffect}from 'react';
import { useTranslation } from "react-i18next";

function AffProgramCalculator(){
    const { t } = useTranslation();
    const[moneyCount, setMoneyCount] = useState(0)
    const[usersCount, setUserCount] = useState(0)

    function valuetext(value) {
        setMoneyCount(Math.round((value * 300) / 100 * 30))
        setUserCount(value)
        return `${value}`;
    }
     
    return(
        <div className='AffProgramCalculator'>
            <h1 className='AffProgramCalculator__h1'>
                {t("affCalculator")}
            </h1>
            <div className='AffProgramCalculator__info'>
                <Slider
                    getAriaValueText={valuetext}
                    step={1}
                    min={0}
                    max={500}
                />
                <p className='AffProgramCalculator__info--sumRef'>{t("allReferals")} {usersCount}</p>
                <div className='AffProgramCalculator__info--text'>
                    <p className='info__text--text'>{t("exampleryMoney")} &nbsp;</p>
                    <p className='info__text--text'> {moneyCount}{t("Month")}</p>
                </div>
            </div>
        </div>
    )
}


export default AffProgramCalculator;