import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const languageKey = 'selectedLanguage';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: languageKey,
      cache: ['cookie']
    },
    interpolation: {
      escapeValue: false
    }
  });

// Проверяем, установлен ли выбранный язык в localStorage
const selectedLanguage = localStorage.getItem(languageKey);

// Если язык ещё не был установлен в localStorage, сохраняем язык, определенный по умолчанию
if (!selectedLanguage) {
    i18n.changeLanguage();
}

// Сохраняем выбранный пользователем язык в localStorage при его изменении
i18n.on('languageChanged', (lang) => {
    localStorage.setItem(languageKey, lang);
});

export default i18n;
