import './Footer.css'
import logo_desktop from '../../images/logo_desktop.webp'

import userAgreement from '../../documents/userAgreement.pdf'
import privacyPolicy from '../../documents/privacyPolicy.pdf'
import { useTranslation } from "react-i18next";

function Footer(){
    const { t } = useTranslation();
    

   


    return(
        <div className='footer'>
            <div>
            <a name='ip__cheker' href='#'><img alt='logo' className='footer__logo'  src={logo_desktop}/></a>
            </div>
            <div className='footer__links'>
                <a name='reviews' href='https://t.me/YourGuard_VPN_bot' className='footer__links--a'>{t("reviews")}</a>
                <a name='affiliateProgram' href='#affiliateProgram' className='footer__links--a'>{t("affiliateProgram")}</a>
                <a name='whyYG' href='#whyYG' className='footer__links--a'>{t("advantages")}</a>
                <a name='tarifs' href='#tarifs' className={window.innerWidth > 600 ? 'footer__links--bottom-link' : 'bottom__link--adapt'}>{t("tariffs")}</a>
                {/* <a name='howGetVPN' href='#howGetVPN' className='footer__links--bottom-link'>{t("howToGetVPN")}</a> */}
                <a name='anyQwestions' href='#anyQwestions' className='footer__links--bottom-link'>{t("contacts")}</a>
            </div>
            
            {window.innerWidth >= 600 &&
                <div className='footer__user--agreements'>
                    <div className='agreements__wrap'>
                        <span onClick={() => window.open(userAgreement, '_blank')}  className='agreements__wrap--span'>{t("userAgreement")}</span>
                        <span onClick={() => window.open(privacyPolicy, '_blank')} className='pointer__class'>{t("privacyPolicy")}</span>
                    </div>
                 </div>
            }
            {window.innerWidth <= 600 &&
                 <div className='agreements__wrap'>
                    <span onClick={() => window.open(userAgreement, '_blank')}className='agreements__wrap--span'>{t("userAgreement")}</span>
                    <span onClick={() => window.open(privacyPolicy, '_blank')}className='pointer__class'>{t("privacyPolicy")}</span>
                </div>
            }
            
        </div>
    )
        
    
}
export default Footer