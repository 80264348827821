 import './HeaderMenu.css'
 import { useTranslation } from "react-i18next";

function HeaderMenu() {
    const { t } = useTranslation();
    return(
        <div className='menu'>
            <div className='menu__links'>
                <a name='whatIsVPN' href='#whatIsVPN' className='menu__links--link'>{t("whatIsVPN")}</a>
                <a name='whyYG' href='#whyYG' className='menu__links--link'>{t("advantages")}</a>
                {/* <a name='howGetVPN' href='#howGetVPN' className='menu__links--link'>{t("howToGetVPN")}</a> */}
                <a name='tarifs' href='#tarifs' className='menu__links--link'>{t("tariffs")}</a>
                <a name='affiliateProgram' href='#affiliateProgram' className='menu__links--link'>{t("affiliateProgram")}</a>
                <a name='anyQwestions' href='#anyQwestions' className='menu__links--link'>{t("contacts")}</a>
                <a name='tg'href='https://t.me/YourGuardVPN_bot'><button className=' first__block--btn header__menu--btn'>{t("button")}</button></a>            </div>
        </div>
    )
}

export default HeaderMenu