import './IP_checker.css'
import axios from 'axios';
import {useState, useEffect}from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";

function IP_checker(){
  const { t } = useTranslation();
    const [country, setCountry] = useState('')
    const [ip, setIp] = useState('')
    const [sity, setSity] = useState('')
    const [ourIp , setOurIp] = useState(false)
    const [loading , setLoading] = useState(false)
    
    
    
    // useEffect(() => {
    //   axios.post('http://localhost:3000/check-ip', {
    //     ip: ip
    //   })
    //   .then(function (response) {
    //     setOurIp(response.data.match);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    // }, [])
    
    
    
      useEffect(() => {
        fetch('https://ipapi.co/json/') // find another api service
        .then(d => d.json())
        .then(d => {
  
          setSity(d.city)
          setIp(d.ip)
          setCountry(d.country_name)
          
        })
       
        
      }, []);
    
    

    useEffect(() => {
      const timer = setTimeout(() => {
        setLoading(true)
      }, 1500);
      return () => clearTimeout(timer);
    }, []);
      
    
     
    return(
        <div className='checker__line'>
            {loading &&
              <p className='checker__line--text'><span>{t("ip")}</span><u>{ip}</u> <i> ({country}, {sity})</i> </p>
            }
            {!loading &&
              <div className='loading__wrap'>
                <motion.div
                  initial={{  y: 0 }}
                  animate={{  y:  [ 0, -6 , 0]}}
                  transition={{ duration: 0.8,   delay: 0.2}}
                  className='loading__wrap--ball'
                ></motion.div>
                <motion.div 
                   initial={{  y: 0 }}
                   animate={{  y:  [ 0, -6 , 0]}}
                   transition={{ duration: 0.8,  delay: 0.5 }}
                   className='loading__wrap--ball'
                ></motion.div>
                <motion.div  
                  initial={{  y: 0 }}
                  animate={{  y:  [ 0, -6 , 0]}}
                  transition={{ duration: 0.8,  delay: 0.8 }}
                  className='loading__wrap--ball'></motion.div>
              </div>
            }
        </div>
    )
}


export default IP_checker